import React from 'react';
import Img from 'gatsby-image';

import '../reusable/assets/css/header-image.scss';

export default ({ fluid }) => {

  return (
    <div className="header-image">
      <Img fluid={fluid} />
    </div>    
  );
};