import React from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby';

import '../reusable/assets/css/author-caption.scss';

export default ({ data }) => {
  // console.log('data: ', data);

  const createMarkUp = html => ({ __html: html });

  const getSocialLinks = handles => {
    return (
      <div className="social-media-links">
          <h5>Follow Me:</h5>
            { handles.map( (handle, i ) => <li key={i}>{handle}</li> ) }
        </div>
    );
  }

  return (
    <section className="author-caption">
      <article>
        { data.headshot !== null ? <Img fluid={data.headshot.fluid} /> : <p>default avatar</p> }
        <div>
          <header>
            <h5>{data.name}</h5>
            <h6>{data.email}</h6>
          </header>

          <div>
            <div dangerouslySetInnerHTML={ createMarkUp(data.bioNode.childMarkdownRemark.html) } />
            <Link to={`/authors/${data.handle}`}>More by {data.name} &#8250;</Link>
          </div>
        </div>
        
        
        <div className="social-media-links"><p>Follow Me:</p></div>
        { data.socialMediaHandles.length > 0 ? getSocialLinks(data.socialMediaHandles) : null }
        
      </article>
    </section>
  );
}