import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

import '../reusable/assets/css/article-callout.scss';

export default ({ node }) => {

  const createMarkup = html => ({__html: html});

  return (
    <div className="article-callout">
      { node.articleLink.headerImage !== null ? <Img fluid={ node.articleLink.headerImage.fluid} /> : null }
      <h5>{ node.bannerText }</h5>
      <div dangerouslySetInnerHTML={ createMarkup(node.textNode.childMarkdownRemark.html) } />
      <Link to={node.articleLink.handle}>{ node.buttonText } &#8250;</Link>
    </div>
  );
}


// ${node.articleLink.canonicalCategory.handle}/