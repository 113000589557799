import React, { useEffect, useState } from 'react';
import Img from 'gatsby-image';
import useWindowWidth from '../../utils/useWindowWidth';
import './assets/css/product-callout.scss';

export default ({ node }) => {

  const [width, setWidth] = useState(null);

  const windowSize = useWindowWidth();

  useEffect(
    () => {
      setWidth(windowSize.width)
    }, [windowSize.width]
  );

  const isThereATitle = image => image !== null ? <h4>{image.title}</h4> : <h4>Need a Title</h4>;
  const isThereAnImageToDisplay = image => image !== null ? <Img fluid={image.fluid} /> : null;

  const isThereADescription = html =>  html ? <div dangerouslySetInnerHTML={ createMarkup(html) } /> : null;
  const createMarkup = html => ({__html: html});

  const getClassName = () => width < 768 ? "callout-content-wrapper block" : "callout-content-wrapper grid-50-50";

  return (
    <div className={"product-callout-wrapper"}> 
      <header><h4 className="product-callout-tab">{node.bannerText ? node.bannerText : "Customers Love It"}</h4></header>
      <div className={ getClassName() }>
          { isThereAnImageToDisplay(node.productLink.image) }
          <div className="product-callout-desc-wrapper">
              {isThereATitle(node.productLink.image)}
              { isThereADescription(node.productLink.descriptionNode.childMarkdownRemark.html) }
              <button className="red cta-button">{node.buttonText}</button>
          </div>
      </div>
    </div>
  );
}