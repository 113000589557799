import React from 'react';
import { Breadcrumb } from 'gatsby-plugin-breadcrumb';

import '../reusable/assets/css/breadcrumbs-wrapper.scss';

export default (props) => {

  const { crumbs, customCrumbLabel } = props;

//  console.log('props: ', props)

  return (
    <div className="breadcrumbs-wrapper">
      <Breadcrumb
        crumbs={crumbs}
        crumbSeparator=" - "
        crumbLabel={customCrumbLabel}
      />
    </div>
  );
}