import React from 'react';
import { Link } from 'gatsby';
import ImageTextTile from './image-text-tile';

import '../reusable/assets/css/image-text-tile.scss';


export default ({ articles }) => {

  const loadRelatedArticles = articles => articles.map( article => {
    return <Link key={article.id} to={article.handle}><ImageTextTile data={article} /></Link>
  }); 

  return (
    <div className="image-text-tile-wrapper">
      <header><h2>{"Recommended Reading"}</h2></header>
      { loadRelatedArticles(articles) }
    </div>
  );
}
