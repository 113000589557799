import React from 'react';

import '../reusable/assets/css/video-callout.scss';

// default url https://www.youtube.com/watch?v=<video id>

export default ({ node }) => {

  console.log('node in video: ', node);

  return (
    <div className="embed-container">
      <iframe
        src={`https://www.youtube.com/embed/${node.videoId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen>
      </iframe>
    </div>
  );
};
