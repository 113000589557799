import React from 'react';
import { Link } from 'gatsby';

export default ({ data }) => {

  return (
    <div className="tile-content center">
      <h4>{data.node.name}</h4>
      <h5>{data.node.email}</h5>
      <Link to={`/authors/${data.node.handle}`}>More by {data.node.name}</Link>
    </div>
  );
}