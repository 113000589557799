import React from 'react';

export default ({ node: { headerText, layoutType, textNode: { childMarkdownRemark: { html } }} }) => {

  const getClassName = layoutType => layoutType ? layoutType : null;
  const getHeader = header => header ? <h2>{header}</h2> : null;

  const createHtml = html => ({__html: html});

  return (
    <div className={ getClassName(layoutType) }>
      { getHeader(headerText) }
      <div dangerouslySetInnerHTML={ createHtml(html) } />
    </div>
    );
}