import React, { useEffect, useState } from 'react';
import Img from 'gatsby-image';
import useWindowWidth from '../../../utils/useWindowWidth';
import RecommendedReadingDesc from '../image-text-tile/RecomendedReadingDesc';
import AuthorDesc from './AuthorDesc';

export default ({ data }) => {

  const {
    title, headerImage, meta, canonicalCategory, author, ...rest
  } = data;

  const [width, setWidth] = useState(null);
  const windowSize = useWindowWidth();

   useEffect(
    () => {
      setWidth(windowSize.width)
    }, [windowSize.width]
  );

  const gridOrBlock = () => width > 1189 ? "block" : "grid";

  const loadRecommendedReadingDesc = recommendedReading => <RecommendedReadingDesc data={recommendedReading} />;

  const loadAuthorDesc = authorInfo => <AuthorDesc data={authorInfo} />;

  const decideDescComponent = () => {
    if (canonicalCategory) {
      return  loadRecommendedReadingDesc({canonicalCategory, title, author, meta});
     } else  {
      return loadAuthorDesc({ ...rest });
     } 
  }

  return (
    <div className={ `image-text-tile ${gridOrBlock()}` } >
    { (headerImage && headerImage !== null) ? <Img fluid={headerImage.fluid} /> : <Img fluid={data.node.headshot.fluid} /> }
    { decideDescComponent() }
  </div>
  );
}
