import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Link } from 'gatsby';

import '../reusable/assets/css/explore-categories-callout.scss';

export default () => {

  return (
    <div className="explore-categories-callout">
      <h2>Explore Categories</h2>
      <StaticQuery
        query={graphql`
          query MyQuery {
            allDatoCmsCategory {
              edges {
                node {
                  title
                  handle
                  headerImage {
                    alt
                    fluid(maxWidth: 2200, imgixParams: { fm: "jpg", auto: "compress" }) { 
                      ...GatsbyDatoCmsFluid
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => {
          const { allDatoCmsCategory: { edges } } = data;
          const createCategoryTiles = categories => categories.map( category => category.node.headerImage ? (<Link to={`/${category.node.handle}`}><div style={{ background: `url(${category.node.headerImage.fluid.src})`}}><h4>{category.node.title}</h4></div></Link>) : null);

          return (
            <div className="category-wrapper">
              { createCategoryTiles(edges) }
            </div>
           );
          }
        }
      />
    </div>
  );
}
