import React from 'react';
import '../reusable/assets/css/engagement-banner.scss';

// engagement banner should take a layout name which will be a className to assign and change the layout accordingly

export default ({ data: {
  layout,
  image: {
    src,
    alt
  },
  content: {
    h3,
    p,
    button
  }
} }) => {

  // console.log('data: ', data);

  return (
    <section className={`engagement-banner ${layout}`}>
      <img src={src} alt={alt} />
      <div className="engagement-content">
        <h3>{h3}</h3>
        <p>{p}</p>
        { button ? <button>{button}</button> : null }
      </div>
    </section>
  );
}