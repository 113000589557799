import React from 'react';

import './assets/css/newsletter-signup.scss';



export default () => {
  return (
    <section className="newsletter-signup">
      <header>
      <h2>{"Get free exclusive content in your inbox"}</h2>
      <p>{"Learn to brew world-class coffee at home like the pro we all know you can be. Subscribe for free and get exclusive content, product giveaways, and more."}</p>
      </header>
      <form>
        <div>
          <input type="email" placeholder={"Enter your email address"}></input>
          <button>&#8594;</button>
        </div>
      </form>
    </section>
  );
}