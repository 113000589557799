import React from 'react';

export default ({ 
  data: {
    canonicalCategory, title, author, meta
  }
}) => {

  return (
    <div className="tile-content">
      <header><h6>{canonicalCategory.title}</h6></header>
      <header><h4>{title}</h4></header>
      <footer><p>By {author.name} - {meta.publishedAt}</p></footer>
    </div>
  );
}