import React, { useState, useEffect } from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import BreadcrumbsWrapper from '../components/reusable/BreadcrumbsWrapper';
import { graphql } from 'gatsby';

import HeaderImage from '../components/reusable/HeaderImage';
import HeaderText from '../components/reusable/HeaderText';
import ProductCallout from '../components/reusable/ProductCallout';
import SocialIcons from '../components/main-site-nav/mobile/socialIcons';
import ExploreCategories from '../components/reusable/ExploreCategories';
import RecommendedReading from '../components/reusable/RecommendedReading';
import ArticleCallout from '../components/reusable/ArticleCallout';
import VideoCallout from '../components/reusable/VideoCallout';

import AuthorCaption from '../components/reusable/AuthorCaption';

import EngagementBanner from '../components/reusable/EngagementBanner';

import useWindowWidth from '../utils/useWindowWidth';

import NewsletterSignup from '../components/reusable/newsletterSignup';

import '../components/assets/css/article.scss';

export const getArticle = graphql`
 query ($handle: String!) {
      datoCmsArticle(handle: {eq: $handle}) {
        title
        handle
        seoMetadata {
          description
          title
          twitterCard
          image {
            fluid(maxWidth: 2200, imgixParams: { fm: "jpg", auto: "compress" }) { 
              ...GatsbyDatoCmsFluid
            }
          }
        }
        tags
        canonicalCategory {
          id
          handle
          headerImage {
            fluid(maxWidth: 2200, imgixParams: { fm: "jpg", auto: "compress" }) { 
              ...GatsbyDatoCmsFluid
            }
          }
          handle
          title
        }
        linkingCategories
        internalNotes
        recommendedArticles {
          title
          handle
          headerImage {
            fluid(maxWidth: 2200, imgixParams: { fm: "jpg", auto: "compress" }) { 
              ...GatsbyDatoCmsFluid
            }
          }
          meta {
            publishedAt(fromNow: true)
          }
          canonicalCategory {
            id
            handle
            title
          }
          author {
            handle
            email
            name
          }
        }
        author {
          email
          handle
          headshot {
            fluid(maxWidth: 600, imgixParams: { fm: "jpg", auto: "compress" }) { 
              ...GatsbyDatoCmsFluid
            }
          }
          name
          bioNode {
            childMarkdownRemark {
              excerpt(format: HTML)
              html
            }
          }
          socialMediaHandles {
            accountName
            service
          }
        }
        meta {
          publishedAt(fromNow: true)
        }
        headerImage {
          fluid(maxWidth: 2200, imgixParams: { fm: "jpg", auto: "compress" }) { 
            ...GatsbyDatoCmsFluid
          }
        }
        excerptNode {
          childMarkdownRemark {
            html
          }
        }
        articleBody {
          ... on DatoCmsBlockText {
            id
            textNode {
              childMarkdownRemark {
                html
              }
            }
            layoutType
            headerText
          }
          ... on DatoCmsBlockArticle {
              id
              textNode {
                childMarkdownRemark {
                  html
                }
              }
              buttonText
              layoutType
              bannerText
              articleLink {
                title
                handle
                canonicalCategory {
                  handle
                  title
                }
                excerptNode {
                  childMarkdownRemark {
                    html
                  }
                }
                author {
                  email
                  handle
                  headshot {
                    fluid(maxWidth: 2200, imgixParams: { fm: "jpg", auto: "compress" }) { 
                      ...GatsbyDatoCmsFluid
                    }
                  }
                  name
                  bioNode {
                    childMarkdownRemark {
                      excerpt(format: HTML)
                      html
                    }
                  }
                }
                headerImage {
                  fluid(maxWidth: 2200, imgixParams: { fm: "jpg", auto: "compress" }) { 
                    ...GatsbyDatoCmsFluid
                  }
                }
              }
            }
            ... on DatoCmsBlockImage {
              id
              caption
              image {
                fluid {
                  width
                }
              }
              link
              layoutType
            }
            ... on DatoCmsBlockOutbound {
              id
              text
              bannerText
              linkDestination
            }
          ... on DatoCmsBlockProduct {
            id
            bannerText
            buttonText
            layoutType
            leadInTextNode {
              childMarkdownRemark {
                html
              }
            }
            text
            productLink {
              slug
              name
              affiliateLinks {
                url
                name
              }
              descriptionNode {
                childMarkdownRemark {
                  html
                }
              }
              image {
                fluid(maxWidth: 2200, imgixParams: { fm: "jpg", auto: "compress" }) { 
                  ...GatsbyDatoCmsFluid
                }
                title
              }
            }
          }
          ... on DatoCmsBlockVideo {
            id
            bannerText
            leadInText
            videoId
            videoSource
          }
        }
      }
  }
`;


export default ({ data, pageContext, location }) => {

  const [width, setWidth] = useState(null);
  const windowSize = useWindowWidth();

  useEffect(
    () => {
      setWidth(windowSize.width)
    }, [windowSize.width]
  );

  const {
    datoCmsArticle: {
      id,
      title,
      handle,
      headerImage,
      recommendedArticles,
      meta: { publishedAt },
      excerptNode,
      author,
      articleBody,
      canonicalCategory

    }
  } = data;

  // console.log('data in article js: ', data)

  const {
    breadcrumb: {
      crumbs
    }
  } = pageContext;

 
  const customCrumbLabel = location.pathname.toLowerCase().replace(/\/.*\//, '');

  const createMarkup = html => ({ __html:  html});

  const createHeaderImage = (headerImage) => {
  return <HeaderImage fluid={headerImage.fluid} />;
  }
  
  const createExcerpt = html => html ? <div dangerouslySetInnerHTML={ createMarkup(html) } /> : null;

  const parseBody = arr => arr.map( (datoBlock, i) => {
    // console.log('datoBlock for article body: ', datoBlock);

    switch (datoBlock.__typename) {
      case 'DatoCmsBlockText':
        return <HeaderText key={datoBlock.id} node={datoBlock} />

      case 'DatoCmsBlockArticle':
        return <ArticleCallout key={datoBlock.id} node={datoBlock} />;

      case 'DatoCmsBlockImage':
        console.log('imageBlock datoBlock: ', datoBlock);
        return;

      case 'DatoCmsBlockOutbound':
        console.log('Outbound block datoBlock: ', datoBlock);
        return;
      
      case 'DatoCmsBlockVideo':
        return <VideoCallout key={datoBlock.id} node={datoBlock} />;

      case `DatoCmsBlockProduct`:
        return <ProductCallout key={datoBlock.id} node={datoBlock} />
      
      default:
        console.log('default case')
    }
    
  });

  const bannerInfo = {
    layout: "tall-image",
    image: {
      src: "https://via.placeholder.com/500x700.png/09f/fff",
      alt: "img alt text"
    },
    content: {
      h3: "Brew like you bean it.",
      p: "Learn to brew world-class coffee at home like the pro we all know you can be. Subscribe for free and get exclusive content, product giveaways, and more.",
      button: "Your inner barista is calling..."
    }
  };

  return (
    <Layout>
      <SEO title={title} description={title} />
      <BreadcrumbsWrapper crumbs={crumbs} crumbLabel={customCrumbLabel} />
    <section>
      <article className="article-layout">
        <div className="main-article-header-wrapper">
          { headerImage ? createHeaderImage(headerImage) : null }
          { width > 1189 ? <SocialIcons fillColor={"#283593"} /> : null }
          <header className="main-article-header">
            <h5>{canonicalCategory.title}</h5>
            <h1>{title}</h1>
            { createExcerpt(excerptNode.childMarkdownRemark.html) }
            <p>By {author.name} &#183; {publishedAt}</p>
          </header>
        </div>

        <EngagementBanner data={bannerInfo} />

        <div className="article-body">
          { parseBody(articleBody) }
        </div>

        <AuthorCaption data={author} />

        { recommendedArticles.length > 0 ? <RecommendedReading articles={recommendedArticles} /> : null }

        <ExploreCategories />

        <NewsletterSignup />

      </article>
    </section>
    </Layout>
  );

}